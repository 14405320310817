@use "../../../styles/partials/mixins" as *;
@use "../../../styles/partials/variables";

.tech-spec {
  @include flex(center, center, column);
  height: fit-content;
  width: 100%;

  background-color: $dark-lichen;

  @include big-tablet {
    height: 100%;
    width: 30%;
  }

  &__container {
    width: 100%;
    height: 100%;
    @include flex(center, center, column);
    padding: 3rem;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    @include big-tablet {
      @include flex(center, flex-start, column);
    }
  }

  &__item {
    @include flex(center, center, column);
    margin-bottom: 2rem;

    @include big-tablet {
      @include flex(left, flex-start, column);
    }

    &-line {
      border-bottom: 2px solid black;
      width: 6rem;
    }
  }

  &__text {
    color: black;
    margin-bottom: 1rem;
    text-align: center;
  }

  &__measurement {
    color: black;
    margin-bottom: 2rem;
    @include flex(center, center, row);

    width: fit-content;

    &-bold {
      font-size: 3rem;
      color: black;
      text-align: center;
      width: fit-content;
      margin-right: 0.5rem;
      font-weight: 600;

      @include big-tablet {
        text-align: left;
      }
    }
  }
}
