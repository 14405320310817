@use "../../../styles/partials/mixins" as *;
@use "../../../styles/partials/variables";

.modular {
  @include flex(left, center, column);
  z-index: -1;
  background-color: $lichen;

  &__container {
    width: 100%;
    height: fit-content;
    margin-bottom: 4rem;
    @include flex(center, center, column);

    @include big-tablet {
      height: 100vh;
      margin-bottom: unset;
      @include flex(center, center, row);
    }
  }

  &__content {
    @include flex(center, center, column);

    &-box {
      width: 100%;
      height: fit-content;
      padding: 10rem 0 5rem 0;
      // height: 70%;

      @include big-tablet {
        width: 70%;
        height: 100%;
      }
    }
  }

  &__image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    display: inline-block;
    &-container {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    &-list {
      overflow: hidden;
      height: 30%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 2rem;
      @include big-tablet {
        margin-right: 2rem;
      }
    }
  }

  &__title {
    @include section-title;
    text-align: center;
    width: 100%;
    color: white;

    margin-bottom: 8rem;

    @include big-tablet {
      text-align: left;
    }
  }

  &__text-list {
    margin-top: 2rem;

    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2rem;

    @include big-tablet {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &__text-item {
    margin-bottom: 2rem;

    & * {
      color: black;
    }
    & p {
      text-align: center;
      @include big-tablet {
        text-align: left;
      }
    }
    & p:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}
