@use "../../../styles/partials/mixins" as *;
@use "../../../styles/partials/variables";

.story-intro {
  @include flex(left, center, column);
  background-color: $lichen;
  height: fit-content;

  @include big-tablet {
    height: 100vh;
  }

  &__container {
    height: 100%;
    width: 100%;
    @include flex(space-between, center, column);
    @include big-tablet {
      @include flex(space-between, center, row);
    }
  }

  &__title {
    @include section-title;
    font-weight: 500;
    font-size: 3.7rem !important;
    width: 100% !important;
    color: white;

    &-bold {
      color: white;
      font-weight: 600;
      margin: 0 0.3rem;
    }
  }

  &__image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    &-container {
      display: none;
      @include big-tablet {
        display: flex;
        height: 100%;
        width: 40%;
        overflow: hidden;
      }
    }
  }

  &__text {
    width: 100%;
    @include big-tablet {
      width: 50%;
    }
    & * {
      color: black;
    }

    & > * {
      margin-bottom: 1rem;
    }
    &-container {
      width: 100%;
      height: 100%;

      padding: 15rem 4rem 8rem 4rem;
      @include big-tablet {
        width: 60%;
        @include flex(space-between, flex-start, column);
      }
    }
    &-box {
      @include big-tablet {
        @include flex(space-between, flex-start, row);
      }
    }
  }
}
