@import "./mixins";

// // RESETS
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  // scroll-padding-top: 25rem !important;
  scroll-behavior: smooth;
  width: 100vw;
}

body {
  font-size: 1.6rem;
  margin: 0;
  position: relative;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span,
li {
  color: #7d8793;
}

.loading-tag {
  width: 100%;
  @include flex(center, center, row);
  margin: 30rem 0;
}
