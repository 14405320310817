@use "../../../styles/partials/mixins" as *;
@use "../../../styles/partials/variables";

.vp {
  @include flex(left, center, column);

  &__container {
    margin: 7rem 0;
    width: 100%;
    @include flex(center, center, column);
  }

  &__title {
    @include section-title;
  }

  &__heading {
    margin-bottom: 5rem;
  }

  &__lists-box {
    @include flex(center, center, column);
    @include big-tablet {
      @include flex(space-between, flex-start, row);
    }
    width: 100%;
  }

  &__sub-list-left,
  &__sub-list-right {
    margin: 0 3rem;
    width: 100%;
    max-width: 46rem;
    margin-bottom: 3rem;
    @include big-tablet {
      width: 50%;
      margin-bottom: 0;
    }
  }

  &__sub-list {
    margin-bottom: 2rem;
    width: 100%;
  }

  &__spec-item {
    @include flex(center, center, row);
    width: 100%;
    height: fit-content;
    &-text {
      width: 50%;
      background-color: $fnavy;
      height: fit-content;
      border: 3px solid white;
      & p {
        color: white;
        text-align: right;
        padding: 1rem;
        height: fit-content;
      }
    }
    &-number {
      width: 50%;
      background-color: $blue;
      border: 3px solid white;
      height: fit-content;
      & p {
        height: fit-content;
        color: white;
        padding: 1rem;
      }
    }
  }
}
