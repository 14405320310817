$green: rgba(70, 131, 84, 1);
$grey: rgba(227, 225, 226, 1);
$dark-grey: rgba(125, 135, 147, 1);

$light-grey: hsl(0, 0%, 95%);

$lichen: rgba(133, 145, 155, 1);
$dark-lichen: rgba(107, 116, 125, 1);

$green: rgba(166, 179, 163, 1);
$heading_green: #365350;
$text_charcoal: #353535;

$fnavy: #0a2240;
$fnavy: rgba(133, 145, 155, 1);

$blue: #4277e4;

$maroon: rgba(65, 33, 49, 1);
$dark-maroon: rgba(39, 6, 29, 1);

$fade-grey: rgba(15, 15, 15, 1);

$tablet-breakpoint: 768px;
$big-tablet-breakpoint: 1024px;
$desktop-breakpoint: 1280px;
// $max-wrapper-width: 102rem;
