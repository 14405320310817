@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables";

.header {
  position: fixed;
  top: 0;
  z-index: 20;
  height: 10rem;
  width: 100vw;
  @include flex(center, center, row);
  background-color: transparent;

  &__container {
    @include flex(space-between, center, row);
    width: 100%;
    height: 100%;
    max-width: 160rem;
    padding: 0 2rem;
    background-color: transparent;
    @include desktop {
      padding: 0;
    }
  }
}

.nav {
  @include flex(space-between, center, row);
  width: 100%;
  @include big-tablet {
  }

  &__logo {
    width: 12rem;
    filter: invert(1);
    &-link {
      height: fit-content;
      width: fit-content;
    }
  }

  &__list {
    @include flex(center, center, column);
    position: absolute;
    top: 10rem;
    left: 0;
    width: 100vw;
    background-color: white;
    display: none;
    padding-bottom: 2rem;

    @include big-tablet {
      padding-bottom: 0;
      width: fit-content;
      position: static;
      background-color: transparent;
      @include flex(center, center, row);
    }
  }

  &__link {
    color: $fnavy;
    margin: 0 2rem;
    text-decoration: none;
    padding: 1rem 0;
    @include big-tablet {
      color: white;
    }
    &:hover {
      color: $blue;
      transition: 0.6s;
    }
  }

  &__btn {
    padding: 1rem 2rem;
    @include btn(white, $fnavy, 1px solid $fnavy);
    z-index: 20;
    text-align: center;
    width: fit-content;

    position: absolute;
    top: 2.2rem;
    left: 50%;
    transform: translateX(-50%);
    display: none;

    @include big-tablet {
      display: block;
      position: relative;
      top: 0;
      left: 0;
      transform: translateX(0);
      margin: 0 2rem;
    }
  }
}

.header-active {
  background-color: white;
  @include big-tablet {
    background-color: transparent;
  }
}

.header-scrolled {
  background-color: white;
}

.nav__logo-active {
  filter: invert(0);
  @include big-tablet {
    filter: invert(1);
  }
}

.nav__logo-scrolled {
  filter: invert(0);
}

.nav__btn-active {
  display: block;
}

.nav__list-active {
  display: flex;
}

.nav__link-scrolled {
  color: $fnavy;
}

.navbar-burger {
  display: flex;
  cursor: pointer;
  color: white;
  &:hover {
    color: $blue;
    transition: 0.6s;
  }
  @include big-tablet {
    display: none;
  }
}

.navbar-burger-active {
  color: $fnavy;
}

.navbar-burger-scrolled {
  color: $fnavy;
  @include big-tablet {
    color: white;
  }
}
