@use "../../../styles/partials/mixins" as *;
@use "../../../styles/partials/variables";

.single-banner {
  height: 40vh;

  &__image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    display: inline-block;
    &-container {
      height: 100%;
      width: 100%;
      overflow: hidden;
    }
  }
}
