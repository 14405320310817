@use "../../../styles/partials/mixins" as *;
@use "../../../styles/partials/variables";

.intro {
  @include flex(left, center, column-reverse);
  position: relative;
  height: fit-content;
  width: 100%;
  border-top: 6px solid $lichen;
  border-bottom: 6px solid $lichen;
  background-color: $lichen;
  z-index: -2;

  @include big-tablet {
    height: 100vh;
    @include flex(left, center, column);
  }

  &__container {
    height: 100%;
    width: 100%;
    @include flex(left, flex, column);
  }

  &__image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    display: none;
    display: inline-block;

    @include big-tablet {
      display: inline-block;
    }

    &-container {
      height: 100%;
      overflow: hidden;
      width: 100%;
      background-color: $lichen;
      @include big-tablet {
        position: absolute;
        top: 0;
        left: 0;
      }
      z-index: -1;
    }
  }

  &__content-box {
    @include flex(center, flex-start, column);
    width: 100%;
    height: 100%;
    background-color: $lichen;
    padding: 4rem;
    @include big-tablet {
      width: 45%;
    }
  }

  &__title {
    @include section-title;
    color: white;
    text-align: left;
    margin: 0;

    &-bold {
      @include section-title;
      color: white;
      text-align: left;
      margin: 0;
      font-weight: 600;
    }
  }

  &__content {
    margin-top: 4rem;
    & * {
      color: white;
    }

    & > *:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }
}
