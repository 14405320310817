@use "../../../styles/partials/mixins" as *;
@use "../../../styles/partials/variables";

@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.spec-list {
  padding: 6rem 0;
  background-color: $fnavy;
  width: 100%;
  display: block;
  @include flex(center, center, column);

  &__container {
    display: block;
    width: 100%;
  }

  &__list {
    overflow: hidden;
    padding: 6rem 0;
    white-space: nowrap;
  }

  &__list-slider {
    animation: 10s slide infinite linear;
    display: inline-block;
  }

  &__item {
    width: 100%;
    text-align: center;
  }

  &__number-box {
    width: 100%;
    @include flex(center, center, row);
    margin-bottom: 1rem;
  }

  &__number {
    width: fit-content;
    color: $blue;
    text-align: center;
    margin-right: 0.3rem;
  }

  &__measurement {
    width: fit-content;
    color: white;
    text-align: center;
  }

  &__text {
    width: 100%;
    color: white;
    text-align: center;
  }
}
