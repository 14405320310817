@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables";

.contact-form {
  @include flex(left, center, column);
  position: relative;
  height: fit-content;

  &__bg-over {
    background-color: $fnavy;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1;

    // backdrop-filter: blur(10px);
    // opacity: 0.7;
  }

  &__container {
    margin: 7rem 0;
    width: 100%;
    @include flex(space-between, center, column);
    height: fit-content;
  }

  &__title {
    @include section-title;
    font-weight: 500;
    width: 40%;
    color: white;
    text-align: center;
    margin-bottom: 5rem;
    font-size: 2.2rem;
    @include tablet {
      font-size: 3rem;
    }
  }

  &__form {
    height: fit-content;
    width: 100%;

    @include flex(center, center, column);

    & iframe {
      width: 100%;

      @include tablet {
        width: 65rem;
      }
    }
  }

  &__input-box {
    margin-bottom: 3rem;
    width: 100%;
    @include flex(left, flex-start, column);

    &-split {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 2rem;

      @include big-tablet {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  &__label {
    width: 100%;
    color: black;
    margin-bottom: 1.5rem;
  }

  &__input {
    width: 100%;
    padding: 1rem 0.5rem;
    border: 1px solid $grey;
    border-radius: 0.6rem;
    outline: none;
  }

  &__textarea {
    width: 100%;
    padding: 1rem 0.5rem;
    border: 1px solid $grey;
    border-radius: 0.6rem;
    outline: none;
  }

  &__select {
    padding: 1rem 2rem;
    border: 1px solid $grey;
    border-radius: 0.6rem;
    outline: none;
  }

  &__checkbox {
    margin-right: 2rem;
    &-list {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 2rem;
      @include big-tablet {
        grid-template-columns: repeat(2, 1fr);
      }
    }
    &-label {
      margin-right: 1rem;
    }
  }

  &__radio {
    margin-right: 2rem;
    &-list {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 2rem;
      @include big-tablet {
        grid-template-columns: repeat(2, 1fr);
      }
    }
    &-label {
      margin-right: 1rem;
    }
  }

  &__submit {
    width: fit-content;
    color: black;
    padding: 1.5rem 3rem;
    @include btn(white, $fnavy, 1px solid $fnavy);
    border-radius: 3px;
    margin-top: 3rem;

    &-box {
      width: 100%;
      @include flex(center, center, row);
    }
  }
}
