@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables";

.banner {
  width: 100%;
  height: 60vh;
  min-height: 60rem;
  overflow: hidden;
  @include flex(center, center, column);
  position: relative;

  &__container {
    width: 100%;
    height: 100%;
    position: relative;
    @include flex(center, center, column);
  }

  &__slider-item {
    width: 100%;
    height: 100%;
    position: relative;
  }

  &__background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
  }

  &__overlay {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 10;
  }

  &__content {
    height: 100%;
    z-index: 11;
    position: absolute;
    top: 0;
    padding: auto;
    width: 100%;
    @include flex(center, center, column);
    &-heading {
      color: white;
      width: fit-content;
      font-weight: 300;
      line-height: 2rem;
      letter-spacing: 0.2rem;
      text-transform: uppercase;
    }

    &-title {
      color: white;
      font-size: 5rem;
      margin: 2rem 0;
      letter-spacing: 0.2rem;
      text-transform: uppercase;
      width: fit-content;
    }

    &-btn {
      @include btn(white, $fnavy, 1px solid $fnavy);
      width: fit-content;
    }
  }

  & .slick-slider {
    height: 100%;
    width: 100%;

    & .slick-list {
      height: 100%;
      width: 100%;

      & .slick-track {
        height: 100%;

        & .slick-slide {
          height: 100%;

          & > div {
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }

  &-slider {
    height: 100vh;
    min-height: 60rem;
  }

  &__scroll-btn {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2rem;

    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    border: 1px solid white;

    @include flex(center, center, row);

    z-index: 35;
    cursor: pointer;

    &-icon {
      color: white;
    }

    &:hover {
      border: 1px solid $lichen;
      transition: 0.5s;
      .banner__scroll-btn-icon {
        color: $lichen;
        transition: 0.5s;
      }
    }
  }
}
