@import "./styles/partials/resets";

.root {
  width: 100%;
}

.container {
  width: 100%;
  padding: 0 2rem;

  @include desktop {
    padding: 0;
    max-width: 102.4rem !important;
  }
}
