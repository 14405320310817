@use "../../../styles/partials/mixins" as *;
@use "../../../styles/partials/variables";

.tech-break {
  height: 35vh;
  position: relative;

  &__image {
    height: 100%;
    width: 100%;
    display: inline-block;
    &-container {
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
  }

  &__overlay {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(10, 34, 64, 0.4);
    z-index: 2;
  }

  &__content {
    @include flex(space-evenly, flex-start, column);
    @include big-tablet {
      @include flex(space-evenly, center, row);
    }
    z-index: 3;

    &-container {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      @include flex(center, center, column);
    }
  }

  &__title {
    @include section-title;
    color: white;
    margin-right: 13rem;
    width: fit-content;
    font-size: 2.4rem;
  }

  &__text {
    @include big-tablet {
      width: 40%;
    }
    & p {
      color: white;
    }
  }
}
