@use "../../../styles/partials/mixins" as *;
@use "../../../styles/partials/variables";

.press-downloads {
  @include flex(center, center, column);
  width: 100%;

  background-color: $text_charcoal;

  &__container {
    margin: 5rem 0;
    @include flex(center, center, column);
    width: 100%;
  }

  &__title {
    @include section-title;
    font-size: 3.5rem;
    font-weight: 700;
    color: white;
  }

  &__list {
    width: 100%;
    display: grid;
    gap: 3rem;
    grid-template-columns: 1fr; /* Default to 1 column taking full width */

    @include tablet {
      grid-template-columns: repeat(2, 1fr); /* On tablets, 2 columns */
    }

    @include big-tablet {
      grid-template-columns: repeat(3, 1fr); /* On larger screens, 3 columns */
    }
  }

  &__item {
    width: 100%;
    background-color: white;
    padding: 3rem;
    &-image {
      width: 100%;
      height: 100%;
      object-fit: contain;
      &-container {
        width: 100%;
      }
    }

    &-title {
      margin: 2rem 0;
    }

    &-button {
      background-color: black;
      color: white;
      text-decoration: none;
      border: 1px solid black;
      padding: 1.2rem 2.4rem;
      display: block;
      width: fit-content;
      cursor: pointer;
      &:hover {
        color: black;
        background-color: white;
        transition: 0.5s;
      }

      &--no-file {
        background-color: grey;
        color: white;
        text-decoration: none;
        border: 1px solid black;
        padding: 1.2rem 2.4rem;
        display: block;
        width: fit-content;
        position: relative;
        &-overlay {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background-color: rgba(0, 0, 0, 0.4);
          cursor: pointer;
          &:hover {
            background-color: rgba(0, 0, 0, 0.6);
          }
        }
      }
    }
  }
}
