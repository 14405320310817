@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/variables";

.pp {
  height: calc(100vh - 23.7rem);
  width: 100vw;
  min-height: 60rem;
  @include flex(center, center, column);
  position: relative;

  &__bg-cover {
    background-color: $fnavy;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: -1;

    // backdrop-filter: blur(10px);
    // opacity: 0.7;
  }

  &__container {
    @include flex(center, center, column);
    height: 100%;
    width: 100%;
  }

  &__form {
    &-box {
      @include flex(center, center, column);
    }

    &-label {
      color: white;
      padding: 1rem;
    }

    &-input {
      background-color: transparent;
      border: none;
      margin-left: 2rem;
      padding: 1rem;
      border-bottom: 1px solid white;
      color: white;

      &:focus {
        outline: none;
      }
    }
  }

  &__text {
    margin-bottom: 1rem;
    color: white;
  }

  &__link {
    & a {
      margin-left: 0.5rem;
      color: white;
      text-decoration: none;
      font-weight: 600;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
